import axios from "./../../helper/axios"
import config from "./../../config/api.config.json";
import authHeader from '../../config/auth.header';

class PowerBiService{
    
    getAccessToken(request) {
        var data = {}
        // axios.post()
         return axios
        .post(config.POWERBI_GET_ACCESSTOKEN)
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            console.log("error------------------------")
            return {
                status: error.response.status
            }
        })
    }

    getReports(request){
        var data = {
            "auth": request.auth,
            "org":request.org
        };
        return axios
        .post(config.POWERBI_GET_REPORTS,data,{
            headers:authHeader(),
        })
        .then(response => {
            return {
                status: response.status,
                data: response.data
            }
        })
        .catch(error => { 
            return {
                status: error.response.status
            }
        })
    }
}



export default new PowerBiService();